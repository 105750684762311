import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getChartClientRetention: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getChartReturnInvestment`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartIncomeNewBusinessModel: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getChartIncomeNewBusinessModel`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartDigitalClientLtv: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getChartDigitalClientLtv`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartDataRisk: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getChartDataRisk`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartAnualProfit: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getChartAnualProfit`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  saveFixedData: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/saveProfitFixedData`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getFixedData: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getProfitFixedData`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveProfitTrace: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/saveProfitTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getProfitTrace: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/getProfitTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProfitTrace: function (project, idPerformance) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/profit/deleteProfit?idProfit=${idPerformance}`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
