import uaaAxios from "@/utils/authAxios";
const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getAll: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/all`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getInitialAndBalance: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getInitialAndBalance`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getTypes: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getTypes`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getConcepts: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getConcepts`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  saveExpense: function (date, type, concept, expense) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/saveExpense`, {
          date: date,
          type: type,
          concept: concept,
          expense: expense,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  addBudget: function (data) {
    const json = JSON.stringify(data);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/addBudget`, json)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getPieTwo: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getPieTwo`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  timeLineChart: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/timeLineChart`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getAllBudgetByIdProjectAndIdEnterprise: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getAllBudgetByIdProjectAndIdEnterprise`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteBudgetConfigurationByIndex: function (budgetToDelete) {
    const json = JSON.stringify(budgetToDelete);
    return new Promise((resolve, reject) => {
      uaaAxios
        .patch(`${apiURL}/budget/deleteBudgetConfigurationByIndex`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addCostCenter: function (formCostCenterUnits) {
    const json = JSON.stringify(formCostCenterUnits);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/addCostCenter`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getTableCostCenter: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getTableCostCenter`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addExpense: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/addExpense`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  getExpenses: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getExpenses`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getCostCenter: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${apiURL}/budget/chartCostCenter`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getIndexMaxBudgetExpenses: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/getIndexMaxBudgetExpense`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteExpense: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/deleteBudgetExpense`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  getSummaryBudget: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${apiURL}/budget/chartSummaryBudget`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getPeriodTrack: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/budget/chartPeriodTrack`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getTrackByCostCenter: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${apiURL}/budget/chartTrackByCostCenter`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
